import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { FloatOptions, FormatOptions, MobileOptions, NitroAd, ReportPosition } from '../../types';
import { CoreService } from '@Services/core.service';

@Component({
  selector: 'app-video-ad',
  standalone: true,
  imports: [],
  templateUrl: './video-ad.component.html',
  styleUrls: ['./video-ad.component.scss']
})
export class VideoAdComponent implements AfterViewInit, OnDestroy {

  @Input() ad: string;
  @Input() mediaQuery: string;
  float = false;
  closed = false;
  unit: NitroAd;
  visible = false;
  initialized = false;
  @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement>;

  constructor(public core: CoreService) {}

  get floatingState() {
    return this.float ? FloatOptions.Always : FloatOptions.Never;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.mediaQuery) {
      this.visible = window.matchMedia(this.mediaQuery).matches;
    } else {
      this.visible = true;
    }
    if (this.visible && !this.unit) {
      this.initAd().then(() => console.log('video-nc ad initialized'));
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    // if container is not in view, float
    const rect = this.container.nativeElement.getBoundingClientRect();
    const out = rect.bottom < 0;
    if (out !== this.float) {
      this.float = out;
      this.closed = false;
    }
    if (!this.unit && rect.bottom > 0) {
      this.initAd().then(() => console.log('video-nc ad initialized'));
    }
  }

  ngOnDestroy() {
  }

  async initAd() {
    if (this.core.adsDisabled) { return; }
    if (this.initialized) { return; }
    this.initialized = true;
    if (document.getElementById('video-ad')) {
      document.getElementById('video-ad').remove();
      return;
    }
    this.unit = await window.nitroAds.createAd(this.ad, {
      format: FormatOptions.VideoNC,
      mediaQuery: this.mediaQuery,
      video: {
        float: this.floatingState,
        hidePlaylist: true,
        mobile: MobileOptions.Compact
      },
      report: {
        enabled: true,
        wording: 'Report Ad',
        position: ReportPosition.TopRight
      }
    });
  }

  ngAfterViewInit() {
    // check if conainer is in view
    this.onResize();
    this.onScroll();
  }

  close() {
    this.closed = true;
  }
}
